import React from 'react';
import AuthCode from 'react-auth-code-input';
import styles from './pairing_code.module.css';

export interface SimpleTextInputInputProps {
    onChange?: (value: string) => void;
}

const PairingCodeInput: React.FC<SimpleTextInputInputProps> = props => {
    return (
        <AuthCode
            characters={6}
            containerClassName={styles.container}
            inputClassName={styles.input}
            onChange={val => props.onChange?.(val)}
        />
    );
};

export default PairingCodeInput;
