import styles from './Checkbox.module.css';

export interface CBProps {
    storeName: string;
    onClick?: () => void;
    isSelected: boolean;
}

const Checkbox: React.FC<CBProps> = props => {
    return (
        <div className={styles.cbWrapper} onClick={props.onClick}>
            <input checked={props.isSelected} className={styles.checkbox} name={props.storeName} type="checkbox" />
            <label className={props.isSelected ? styles.selected : styles.label} htmlFor={props.storeName}>
                {props.storeName}
            </label>
        </div>
    );
};

export default Checkbox;
