import _ from 'lodash';
import DependencyType from '../../di/DependencyType';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
    browserSessionPersistence,
    initializeAuth,
    User,
} from 'firebase/auth';
import { inject, injectable } from 'inversify';
import { AuthenticatedUserInfo, AuthenticationResult } from '../../utils/authentication/AuthenticationResult';
import { AuthenticationState } from '../../utils/authentication/AuthenticationState';
import { UserRole } from '../../utils/authentication/UserRole';
import {
    GenerateMfaRequestDocument,
    GenerateMfaRequestMutation,
    GenerateMfaRequestMutationVariables,
    GenerateMfaSecretDocument,
    GenerateMfaSecretMutation,
    GenerateMfaSecretMutationVariables,
    VerifyMfaRequestDocument,
    VerifyMfaRequestMutation,
    VerifyMfaRequestMutationVariables,
} from '../../graphql/generated_types';

export interface MFASecret {
    secret: string;
    qr: string;
    uri: string;
}

@injectable()
export class AuthService {
    @inject(DependencyType.ApolloClient)
    private apolloClient!: ApolloClient<NormalizedCacheObject>;

    constructor() {}

    private hasSetupFirebase = false;
    private hasReceivedAUserObject = false;
    private lastKnownToken = '';

    setup(authenticationResult: AuthenticationResult): void {
        if (!this.hasSetupFirebase) {
                const configObject = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? '');
                const app = initializeApp(configObject as FirebaseOptions);

                const auth = initializeAuth(app, { persistence: browserSessionPersistence });
                auth.onAuthStateChanged(async user => {
                    if (user) {
                        console.debug('Loaded Firebase User: ', user.email);

                        authenticationResult.setAuthenticationResult?.(
                            AuthenticationState.Authenticated,
                            await this.createUserInfo(user, () => {
                                authenticationResult.setAuthenticationResult?.(
                                    AuthenticationState.Unauthenticated,
                                    undefined,
                                );
                            }),
                        );
                    } else {
                        console.debug('Could not load Firebase User');

                        authenticationResult.setAuthenticationResult?.(
                            AuthenticationState.Unauthenticated,
                            undefined,
                        );

                        if (!window.location.href.includes('/account')) {
                            window.location.href = '/account/login';
                        }
                    }
                    this.hasReceivedAUserObject = true;
                });
                auth.onIdTokenChanged(user => {
                    user?.getIdToken().then(async t => {
                        console.debug('New firebase token: ', t);
                        if (t !== this.lastKnownToken) {
                            this.lastKnownToken = t;
                            authenticationResult.setAuthenticationResult?.(
                                AuthenticationState.Authenticated,
                                await this.createUserInfo(user, () => {
                                    authenticationResult.setAuthenticationResult?.(
                                        AuthenticationState.Unauthenticated,
                                        undefined,
                                    );
                                }),
                            );
                        }
                    });
                });
            this.hasSetupFirebase = true;
        }
    }

    createUserInfo = async (firebaseUser: User, invalidCallback: () => void): Promise<AuthenticatedUserInfo> => {
        const usersTokenResult = await firebaseUser.getIdTokenResult();
        const roles = usersTokenResult.claims['roles'] ?? [];
        if (!Array.isArray(roles) || roles.length === 0) {
            invalidCallback();
            return {
                name: 'Invalid user',
                email: 'admin@cloudshelf.ai',
                role: UserRole.Unknown,
                hasMFA: false,
            };
        }
        const hasAdminClaim = _.includes(roles, 'admin');
        const hasSupportClaim = _.includes(roles, 'support');
        const hasRetailerClaim = _.includes(roles, 'retailer');

        // MFA
        const hasMFA = !!usersTokenResult.claims['hasMFA'];
        const mfaID = usersTokenResult.claims['mfaID'] as string | undefined;

        let role = UserRole.Unknown;
        if (hasAdminClaim) {
            role = UserRole.Admin;
        } else if (hasSupportClaim) {
            role = UserRole.SupportStaff;
        } else if (hasRetailerClaim) {
            role = UserRole.Retailer;
        }

        return {
            name: firebaseUser.displayName ?? 'Cloudshelf User',
            email: firebaseUser.email ?? 'admin@cloudshelf.ai',
            role,
            hasMFA,
            mfaID,
            hasStoreFinderAccess: hasAdminClaim || hasSupportClaim
        };
    };

    async generateMFARequest(): Promise<string | undefined> {
        try {
            const { data, errors } = await this.apolloClient.mutate<
                GenerateMfaRequestMutation,
                GenerateMfaRequestMutationVariables
                >({
                mutation: GenerateMfaRequestDocument,
            });

            if (errors) {
                return undefined;
            }

            return data?.generateMFARequest;
        } catch (e) {
            return undefined;
        }
    }

    async verifyMFARequest(requestId: string, authcode: string): Promise<string | undefined> {
        try {
            const { data, errors } = await this.apolloClient.mutate<
                VerifyMfaRequestMutation,
                VerifyMfaRequestMutationVariables
                >({
                mutation: VerifyMfaRequestDocument,
                variables: {
                    requestId,
                    authcode,
                },
            });

            if (errors) {
                return undefined;
            }

            return data?.verifyMFARequest;
        } catch (e) {
            return undefined;
        }
    }

    async generateMFASecret(): Promise<MFASecret | undefined> {
        try {
            const { data, errors } = await this.apolloClient.mutate<
                GenerateMfaSecretMutation,
                GenerateMfaSecretMutationVariables
                >({
                mutation: GenerateMfaSecretDocument,
            });

            if (errors) {
                return undefined;
            }

            return data?.generateMFASecret;
        } catch (e) {
            return undefined;
        }
    }
}
