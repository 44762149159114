import React from 'react';
import { useAuthenticatedSession } from '../hooks/useAuthenticatedSession';
import { dependenciesContainer } from '../di/DependencyInitializer';
import { AuthService } from '../di/services/AuthService';
import DependencyType from '../di/DependencyType';

const FireStarter: React.FC = ({ children }) => {
    const authenticationResult = useAuthenticatedSession();
    const authService = dependenciesContainer.get<AuthService>(DependencyType.AuthService);
    authService.setup(authenticationResult);
    return <>{children}</>;
};

export default FireStarter;
