import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuth } from 'firebase/auth';

export function buildApolloClient(): ApolloClient<NormalizedCacheObject> {
    const authLink = setContext(async (_, { headers }) => {
        // get the authentication token from whereever it exists - This is your choice.
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                'cs-auth': token,
            },
        };
    });

    const cloudshelfHttpLinks = ApolloLink.from([
        authLink,
        new HttpLink({ uri: process.env.REACT_APP_BACKEND_URI }),
    ]);

    return new ApolloClient({
        link: cloudshelfHttpLinks,
        cache: new InMemoryCache(),
    });
}
