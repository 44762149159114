import React, { useState } from 'react';
import {
    AuthenticatedUserInfo,
    AuthenticationResult,
    defaultAuthenticationResult,
} from '../utils/authentication/AuthenticationResult';
import { AuthenticationState } from '../utils/authentication/AuthenticationState';

const AuthenticatedSessionContext = React.createContext<AuthenticationResult>(defaultAuthenticationResult);

export const AuthenticatedSessionProvider: React.FC = ({ children }) => {
    const [currentAuthenticationResult, setCurrentAuthenticationResult] = useState(defaultAuthenticationResult);
    const setAuthenticationResult = (
        state: AuthenticationState,
        userInfo: AuthenticatedUserInfo | undefined,
    ) => {
        setCurrentAuthenticationResult({
            state,
            userInfo,
        });
    };

    return (
        <AuthenticatedSessionContext.Provider value={{ ...currentAuthenticationResult, setAuthenticationResult }}>
            {children}
        </AuthenticatedSessionContext.Provider>
    );
};

export const AuthenticatedSessionConsumer = AuthenticatedSessionContext.Consumer;

export default AuthenticatedSessionContext;
