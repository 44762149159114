import { useInjection } from '../../di/DependencyContext';
import { SearchService } from '../../di/services/SearchService';
import DependencyType from '../../di/DependencyType';
import { useHistory, useParams } from 'react-router-dom';
import React, { createRef } from 'react';
import { format } from 'date-fns';
import { ReactComponent as DropdownArrow } from '../../assets/svg/arrow_drop_down_24px_rounded.svg';
import _ from 'lodash';
import * as CSV from 'csv-string';
import Pagination from '../../components/Pagination';
import styles from './History.module.css';

export default function History() {
    const downloadLinkRef = createRef<HTMLAnchorElement>();
    const searchService = useInjection<SearchService>(DependencyType.SearchService);
    const { page, searchTerm } = useParams<{ page: string; searchTerm: string }>();
    const pageNumber = Number(page) || 1;
    const tableOptions = [
        'Search date',
        'Search location',
        'Radius',
        'Stores',
        'Ecommerce stores',
        'Shopify stores',
        'Searched types',
        'Max result types',
        'User name',
    ];

    type QueryResultMap = {
        [key: string]: string;
    };
    const queryResultMap: QueryResultMap = {
        'Search date': 'queryDate',
        'Search location': 'locationName',
        Radius: 'searchRadius',
        Stores: 'numberOfSearchResults',
        'Shopify stores': 'numberOfMatchingProvidersFound',
        'Max result types': 'maxResultTypes',
        'Ecommerce stores': 'numberOfEcommerceResults',
        'Searched types': 'storeTypes',
        'User name': 'email',
    };

    interface SortOption {
        label: string;
        desc: boolean;
    }

    const [selectedViewOptions, setSelectedViewOptions] = React.useState<string[]>(tableOptions);
    const selectedSortOption = React.useRef<SortOption>({
        label: 'Search date',
        desc: true,
    });
    const historyCount = React.useRef<number>(0);
    const [history, setHistory] = React.useState<any[] | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [searchInput, setSearchInput] = React.useState<string>('');
    const searchInputRef = React.useRef<HTMLInputElement>(null);
    let historyArray: any = [];
    let pageHistory = useHistory();

    const cleanCSV = (csv: string) => {
        //CSV needs to be processed again to prevent formatting issues
        const cleanedCSV = CSV.parse(csv);

        const rowOne = cleanedCSV[0];

        cleanedCSV[0] = [
            '',
            ...(rowOne)
        ];

        return CSV.stringify(cleanedCSV);
    };

    const sortByAsc = (key: string) => {
        if (key === 'Max result types') {
            return (a: any, b: any) => (a[key].length > b[key].length ? 1 : b[key].length > a[key].length ? -1 : 0);
        }
        return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
    };

    const sortByDesc = (key: string) => {
        if (key === 'Max result types') {
            return (a: any, b: any) => (a[key].length > b[key].length ? -1 : b[key].length > a[key].length ? 1 : 0);
        }
        return (a: any, b: any) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        searchService
            .loadSearchHistory({
                amountToSkip: pageNumber > 1 ? (pageNumber - 1) * 20 : 0,
                searchTerm: searchTerm ?? '',
            })
            .then(res => {
                const copy = [...res.storeFinderSearchCombinedResults.results];
                historyCount.current = res.storeFinderSearchCombinedResults.totalCount;

                while (copy.length) {
                    const item = copy.shift();
                    historyArray.push(item);
                }

                if (!selectedSortOption.current.desc) {
                    const sorted = historyArray.sort(sortByAsc(queryResultMap[selectedSortOption.current.label]));
                    setHistory(sorted);
                } else {
                    setHistory(historyArray);
                }
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err.message);
            });

        return () => {
            setErrorMessage('');
        };
    }, [searchTerm, page]);

    if (errorMessage) {
        return <div className="flex-center wrapper">{errorMessage}</div>;
    }

    if (history === null) {
        return <div></div>;
    }

    const handleSort = (option: string) => {
        if (_.eq(selectedSortOption.current.label, option)) {
            selectedSortOption.current = {
                label: option,
                desc: !selectedSortOption.current.desc,
            };
        } else {
            selectedSortOption.current = {
                label: option,
                desc: true,
            };
        }

        const stateCopy = [...history];

        let sorted: any;
        const key: string = queryResultMap[option];

        if (selectedSortOption.current.desc) {
            sorted = stateCopy.sort(sortByDesc(key));
        } else {
            sorted = stateCopy.sort(sortByAsc(key));
        }
        setHistory(sorted);
    };

    const handleDownload = async (name: string, id?: string) => {
        const data = await searchService.downloadCSV({id});

        if (downloadLinkRef.current) {
            downloadLinkRef.current.href = 'data:text/csv;charset=utf-8' + encodeURI(cleanCSV(data));
            downloadLinkRef.current.download=`${name}.csv`
            downloadLinkRef.current.click();
        }
    }

    const handleShopifyDownload = (name: string,data: string) => {
        if (downloadLinkRef.current) {
            downloadLinkRef.current.href = 'data:text/csv;charset=utf-8' + encodeURI(cleanCSV(data))
            downloadLinkRef.current.download=`${name}.csv`
            downloadLinkRef.current.click();
        }
    }

    return (
        <div className="container">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
            <a
                ref={downloadLinkRef}
                download={`entire_database.csv`}
                style={{display: 'none'}}
                href={''
                   // 'data:text/csv;charset=utf-8' + encodeURI(cleanCSV(item.csvData))
                }
                target="_blank"
            />
            <div className="flex-center wrapper">
                <div className={styles.searchWrapper}>
                    <button onClick={() => handleDownload('entire_database')} className={styles.tableButton} style={{marginRight: '1em'}}>Download Everything</button>
                    <div className={styles.dropDown}>
                        <div className={styles.dropDownTitle}>
                            <h2>View</h2>
                            <DropdownArrow className={styles.svgArrow} />
                        </div>
                        <div id={styles.sortDropDown} className={styles.dropDownMenu}>
                            {tableOptions.map((option, index) => (
                                <div
                                    key={index}
                                    className={styles.dropDownItem}
                                    onClick={() => {
                                        if (_.includes(selectedViewOptions, option)) {
                                            const optionsCopy = [...selectedViewOptions];
                                            _.remove(optionsCopy, item => item === option);
                                            setSelectedViewOptions(optionsCopy);
                                        } else {
                                            const optionsCopy = [...selectedViewOptions];
                                            optionsCopy.push(option);
                                            setSelectedViewOptions(optionsCopy);
                                        }
                                    }}
                                >
                                    <input
                                        checked={_.includes(selectedViewOptions, option)}
                                        className={styles.dropDownCheckbox}
                                        name={`cb-${option}`}
                                        type="checkbox"
                                    />
                                    <label htmlFor={`cb-${option}`}>{option}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.dropDown}>
                        <div className={styles.dropDownTitle}>
                            <h2>Sort by</h2>
                            <DropdownArrow className={styles.svgArrow} />
                        </div>
                        <div className={styles.dropDownMenu}>
                            {tableOptions.map((option, index) => (
                                <div key={index} className={styles.dropDownItem} onClick={() => handleSort(option)}>
                                    <label
                                        className={_.eq(selectedSortOption.current.label, option) ? 'bold' : 'reg'}
                                        htmlFor={`cb-${option}`}
                                    >
                                        {option}
                                    </label>
                                    <input
                                        checked={_.eq(selectedSortOption.current.label, option)}
                                        className={styles.sortCheckbox}
                                        name={`cb-${option}`}
                                        type="checkbox"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            pageHistory.push(`/history/${searchInput}`);

                            if (searchInputRef.current) searchInputRef.current.value = '';
                        }}
                    >
                        <input
                            onChange={e => {
                                setSearchInput(e.currentTarget.value);
                            }}
                            ref={searchInputRef}
                            required
                            type="text"
                            className={styles.searchInput}
                            placeholder="Search"
                        />
                    </form>
                </div>
                <div className={styles.tableWrapper}>
                    <table className={styles.historyTable}>
                        <thead>
                            <tr>
                                {tableOptions.map((option, index) =>
                                    _.includes(selectedViewOptions, option) ? <th key={index}>{option}</th> : null,
                                )}
                                <th style={{minWidth: '200px'}}>CSV file</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.length
                                ? history.map((item: any, index: any) => (
                                      <tr key={index}>
                                          {_.includes(selectedViewOptions, 'Search date') ? (
                                              <td>{format(new Date(item.queryDate), 'dd.MM.yyyy')}</td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Search location') ? (
                                              <td>{item.locationName}</td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Radius') ? (
                                              <td>{item.searchRadius + 'km'}</td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Stores') ? (
                                              <td>{item.numberOfSearchResults}</td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Ecommerce stores') ? (
                                              <td>{item.numberOfEcommerceResults}</td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Shopify stores') ? (
                                              <td>{item.numberOfMatchingProvidersFound}</td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Searched types') ? (
                                              <td className={styles.typeNumber}>
                                                  {item.storeTypes.length}
                                                  {item.storeTypes.length ? (
                                                      <div className={styles.hoverList}>
                                                          {item.storeTypes.map((element: string, index: number) => (
                                                              <span className={styles.typeElement} key={index}>
                                                                  {element}
                                                              </span>
                                                          ))}
                                                      </div>
                                                  ) : null}
                                              </td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'Max result types') ? (
                                              <td className={styles.typeNumber}>
                                                  {item.maxResultTypes.length}
                                                  {item.maxResultTypes.length ? (
                                                      <div className={styles.hoverList}>
                                                          {item.maxResultTypes.map((element: string, index: number) => (
                                                              <span className={styles.typeElement} key={index}>
                                                                  {element}
                                                              </span>
                                                          ))}
                                                      </div>
                                                  ) : null}
                                              </td>
                                          ) : null}
                                          {_.includes(selectedViewOptions, 'User name') ? (
                                              <td>{item.email.slice(0, item.email.indexOf('@'))}</td>
                                          ) : null}
                                          <td>
                                              <button className={styles.btn} onClick={() => handleShopifyDownload(`${item.locationName}_shopify_results`,item.csvData)}>Download</button>
                                              <div className={styles.dropdown}>
                                                  <button className={styles.btnReverse} style={{borderLeft: '1px solid navy'}}>
                                                      <i className="fa fa-caret-down"></i>
                                                  </button>
                                                  <div className={styles.dropdownContent}>
                                                      <a href="javascript:void(0)" onClick={() => {
                                                          console.error(item);
                                                          handleDownload(`${item.locationName}_all_results`, item.storeFinderSearchId);
                                                          return false;
                                                      }}>Download All</a>

                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                    {!history.length ? <div className={styles.noResultsMessage}>No results found</div> : null}
                </div>
                {historyCount.current > 20 ? (
                    <Pagination
                        activePage={pageNumber ?? 1}
                        itemsCountPerPage={20}
                        totalItemsCount={historyCount.current}
                        searchTerm={searchTerm}
                    />
                ) : null}
            </div>
        </div>
    );
}
