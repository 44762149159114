import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Pagination.module.css';

export interface PaginationProps {
    activePage: number;
    totalItemsCount: number;
    itemsCountPerPage: number;
    searchTerm: string;
}

const Pagination: React.FC<PaginationProps> = ({ activePage, totalItemsCount, itemsCountPerPage, searchTerm }) => {
    const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

    return (
        <div className={styles.pagination}>
            {activePage > 2 ? (
                <Link className={styles.paginationLink} to={`/history/${searchTerm}/1`}>
                    First page
                </Link>
            ) : (
                <div className={styles.placeholderDiv}></div>
            )}
            <div className={styles.middleLinks}>
                {activePage !== 1 ? (
                    <Link className={styles.paginationLink} to={`/history/${searchTerm}/${activePage - 1}`}>
                        Previous page
                    </Link>
                ) : (
                    <div className={styles.placeholderDiv}></div>
                )}
                {activePage !== totalPages ? (
                    <Link className={styles.paginationLink} to={`/history/${searchTerm}/${activePage + 1}`}>
                        Next page
                    </Link>
                ) : (
                    <div className={styles.placeholderDiv}></div>
                )}
            </div>
            {activePage !== totalPages ? (
                <Link className={styles.paginationLink} to={`/history/${searchTerm}/${totalPages}`}>
                    Last page
                </Link>
            ) : (
                <div className={styles.placeholderDiv}></div>
            )}
        </div>
    );
};

export default Pagination;
