import NavBar from '../components/NavBar';
import { useAuthenticatedSession } from '../hooks/useAuthenticatedSession';
import { useEffect } from 'react';
import { AuthenticationState } from '../utils/authentication/AuthenticationState';

export default function Layout({ children }: any) {
    const auth = useAuthenticatedSession();

    useEffect(() => {
        if(auth.state === AuthenticationState.Unauthenticated) {
            if(!window.location.href.includes('/account')) {
                window.location.href = '/account/login'
            }
        }
    }, [auth.state])

    return (
        <>
            {auth.state === AuthenticationState.Authenticated && <NavBar />}
            {children}
        </>
    );
}
