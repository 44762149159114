import React from 'react';
import { useAuthenticatedSession } from '../hooks/useAuthenticatedSession';
import { AuthenticationState } from '../utils/authentication/AuthenticationState';
import { debugLog } from '../utils/DebugLogger';
import MFAPage from '../pages/account/mfa-login';
import MFAOnboardPage from '../pages/account/mfa-onboard';


const FirebaseAuthCheck: React.FC = ({ children }) => {
    const authenticationResult = useAuthenticatedSession();

    if (!authenticationResult) {
        debugLog('Auth object is undefined; Waiting');
        return <p>Loading</p>;
    } else {
        debugLog(
            `AuthState: ${authenticationResult.state}, UserRole: ${
                authenticationResult.userInfo?.role ?? 'No user Info'
            }, User DisplayName: ${authenticationResult.userInfo?.name ?? 'No user info'}`,
        );
    }

    if (authenticationResult.state === AuthenticationState.AwaitingAuthentication) {
        return <p>Loading</p>;
    }

    if (
        authenticationResult.state === AuthenticationState.Authenticated &&
        authenticationResult.userInfo?.hasMFA &&
        !authenticationResult.userInfo.mfaID
    ) {
        // They have a secret, but haven't done MFA yet this session
        return <MFAPage />;
    }

    if (
        authenticationResult.state === AuthenticationState.Authenticated &&
        !authenticationResult.userInfo?.hasMFA
    ) {
        // They don't have a secret, so need to onboard MFA
        return <MFAOnboardPage />;
    }

    if(authenticationResult.state === AuthenticationState.Authenticated && !authenticationResult.userInfo?.hasStoreFinderAccess) {
        return <>Please ask an admin to give you store finder access</>
    }

    return <>{children}</>;
};

export default FirebaseAuthCheck;
