import React from 'react';
import { useInjection } from '../../di/DependencyContext';
import DependencyType from '../../di/DependencyType';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

import styles from './authpages.module.css';
import { AuthService } from '../../di/services/AuthService';
import PairingCodeInput from '../../components/pairing_code_input';

const MFAPage = () => {
    const [didError, setDidError] = React.useState(false);
    const [authCode, setAuthCode] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [requestId, setRequestId] = React.useState<string>();
    const authService = useInjection<AuthService>(DependencyType.AuthService);

    const generateMFARequest = async () => {
        try {
            const requestId = await authService.generateMFARequest();
            setRequestId(requestId);
        } catch {
            setDidError(true);
        }
    };

    const handleSubmit = async () => {
        if (!requestId) {
            return;
        }

        setLoading(true);

        try {
            const auth = getAuth();
            await authService
                .verifyMFARequest(requestId, authCode)
                .then(token => {
                    if (token) {
                        void signInWithCustomToken(auth, token).then().catch();
                        setDidError(false);
                    } else {
                        console.log('Invalid');
                        setRequestId(undefined);
                        setDidError(true);
                        void generateMFARequest();
                    }
                })
                .catch();
        } catch {
            setDidError(true);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        void generateMFARequest();
    }, []);

    return (
        <div className={styles.container}>
            <img src="/img/cloudshelf_long_logo.png" alt="Cloudshelf" style={{ width: 243 }} />
            <div className={styles.formContainer}>
                <h1 className={styles.h1}>Two Factor Authentication</h1>

                <label className={styles.label}>Authenticator code:</label>
                <span className={`${styles.authInput} ${didError ? styles.errorInput : ''}`}>
                    <PairingCodeInput onChange={value => setAuthCode(value)} />
                </span>

                {didError && (
                    <p className={`${styles.hintText} ${styles.errorText}`}>Invalid token. Please try again.</p>
                )}

                <button
                    className={styles.button}
                    onClick={handleSubmit}
                    disabled={authCode.length !== 6 || !requestId || loading}
                >
                    {loading ? 'Please wait' : 'Login'}
                </button>

                <p className={styles.hintText}>
                    <a
                        href="#"
                        onClick={async () => {
                            const auth = getAuth();
                            await auth.signOut();
                        }}
                    >
                        Log out
                    </a>
                </p>
            </div>
        </div>
    );
};

export default MFAPage;
