import './styles/global.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';
import Layout from './layouts/default';
import Search from './pages/Search';
import History from './pages/History';
import Progress from './pages/Progress';
import LoginPage from './pages/account/login';
import { useAuthenticatedSession } from './hooks/useAuthenticatedSession';
import { AuthenticationState } from './utils/authentication/AuthenticationState';

function App() {
    return (
        <div className="App">
                <Router>
                    <Layout>
                        <Switch>
                            <Route exact path="/">
                                <Search />
                            </Route>
                            <Route path="/history/:searchTerm/:page?">
                                <History />
                            </Route>
                            <Route path="/progress/:id">
                                <Progress />
                            </Route>
                            <Route path="/account/login">
                                <LoginPage/>
                            </Route>

                            <Redirect to="/" />
                        </Switch>
                    </Layout>
                </Router>
        </div>
    );
}

export default App;
