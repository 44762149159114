import { ReactComponent as Logo } from '../../assets/svg/CS icon.svg';
import LongLogo from '../../assets/images/long primary.png';
import Media from 'react-media';
import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.css';
import { getAuth } from 'firebase/auth';

function NavBar() {

    const handleLogout = () => {
        const auth = getAuth();
        void auth.signOut();
    }

    return (
        <nav className={styles.nav}>
            <div className={styles.tabs}>
                <NavLink exact to="/" activeClassName={styles.active} className={styles.tabLink}>
                    Home
                </NavLink>
                <NavLink activeClassName={styles.active} to="/history/all" className={styles.tabLink}>
                    Search history
                </NavLink>
            </div>

            <Media
                queries={{
                    large: '(min-width: 1024px)',
                }}
            >
                {size =>
                    //render full logo section on large screens only

                    size.large ? (
                        <div className={styles.longLogoWrapper}>
                            <img className={styles.longLogo} src={LongLogo} alt="Cloudshelf logo" />
                            <h2 className={styles.subTitle}>eCommerce Retailer Finder</h2>
                            <a className={styles.signOut} href="#" onClick={handleLogout}> Sign Out </a>
                        </div>

                    ) : (
                        <div className={styles.mobileLogo}>
                            <Logo title="Cloudshelf logo" width="35px" />
                            <a className={styles.signOut} href="#" onClick={handleLogout}> Sign Out </a>
                        </div>
                    )
                }
            </Media>
        </nav>
    );
}

export default NavBar;
