import React from 'react';
import SelectAll from '../../components/SelectAll';
import { Store, StoreCategory, allStores, getAllStoresOfType } from '../../utils/stores';
import Checkbox from '../../components/Checkbox';
import _ from 'lodash';
import { ReactComponent as Arrow } from '../../assets/svg/chev_arrow.svg';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Autocomplete from 'react-google-autocomplete';
import { useHistory } from 'react-router-dom';
import { useInjection } from '../../di/DependencyContext';
import { SearchService } from '../../di/services/SearchService';
import DependencyType from '../../di/DependencyType';
import styles from './Search.module.css';
import { useAuthenticatedSession } from '../../hooks/useAuthenticatedSession';

function Search() {
    const authResult = useAuthenticatedSession();
    const [storeSelection, setStoreSelection] = React.useState<Store[]>([]);
    const [locationName, setLocationName] = React.useState<string>('');
    const [searchCoordinates, setSearchCoordinates] = React.useState<string>('');
    const [searchRadius, setSearchRadius] = React.useState<number>(3);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const searchService = useInjection<SearchService>(DependencyType.SearchService);
    let history = useHistory();

    const handleSelectAll = (category?: StoreCategory) => {
        if (category === undefined) {
            return setStoreSelection(allStores);
        }

        const filtered = getAllStoresOfType(category);
        setStoreSelection(_.uniq(_.concat(storeSelection, filtered)));
    };

    const numberOfTypeSelected = (category: StoreCategory): number => {
        return _.filter(storeSelection, store => store.category === category).length;
    };

    const allStoresOfTypeAreSelected = (type: StoreCategory): boolean => {
        const allStoresOfType = getAllStoresOfType(type);

        return allStoresOfType.every((store: Store) => storeSelection.includes(store));
    };

    const requiredOptionsAreSelected = () => {
        return _.every([searchCoordinates, locationName, searchRadius, storeSelection], value => {
            if (typeof value === 'number') {
                return value >= 0;
            } else {
                return value.length > 0;
            }
        });
    };

    const handleLaunchSearch = () => {
        if (requiredOptionsAreSelected()) {
            searchService
                .launchSearch({
                    storeTypes: _.map(storeSelection, value => value.name),
                    email: authResult.userInfo?.email ?? 'admin@cloudshelf.ai',
                    radius: searchRadius,
                    coordinates: searchCoordinates,
                    locationString: locationName,
                })
                .then(launchId => {
                    history.push(`/progress/${launchId}`);
                })
                .catch(err => console.log(err));
        } else {
            setErrorMessage('Error: required options missing');
        }
    };

    const handleSelectClick = (store: Store) => {
        if (storeSelection.includes(store)) {
            const stateCopy = [...storeSelection];
            _.remove(stateCopy, (str: Store) => store === str);
            setStoreSelection(stateCopy);
        } else {
            const stateCopy = [...storeSelection];
            stateCopy.push(store);
            setStoreSelection(stateCopy);
        }
    };

    const handleUnSelectAll = (category?: StoreCategory) => {
        if (category === undefined) {
            return setStoreSelection([]);
        }

        const filtered = _.filter(storeSelection, (store: Store) => store.category !== category);
        setStoreSelection(filtered);
    };

    const storeCategories = Object.values(StoreCategory);

    return (
        <div className="container">
            <div className="wrapper flex-center">
                <div className={styles.section}>
                    <div className={styles.inputGroup}>
                        <Autocomplete
                            placeholder="Location"
                            className={styles.textInput}
                            onChange={e => {
                                if (e.currentTarget.value === '') {
                                    setLocationName('');
                                    setSearchCoordinates('');
                                }
                            }}
                            onPlaceSelected={place => {
                                const coords = `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`;
                                setSearchCoordinates(coords);
                                setLocationName(place.name);
                            }}
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                            options={{
                                types: ['geocode'],
                                fields: ['geometry', 'name'],
                            }}
                        />
                        <input
                            name="radius"
                            autoComplete="off"
                            className={styles.textInput}
                            onChange={e => {
                                setSearchRadius(Number(e.currentTarget.value));
                            }}
                            type="number"
                            min="1"
                            max="50"
                            step=".5"
                            placeholder="Search radius (km)"
                            required
                            list="suggestedNumbers"
                        />
                        <datalist id="suggestedNumbers">
                            <option value="1" />
                            <option value="2.5" />
                            <option value="5" />
                            <option value="10" />
                            <option value="20" />
                        </datalist>
                    </div>
                    <div className={styles.row}>
                        <h2 className={styles.boldLabel}>Select type</h2>

                        <SelectAll
                            text={storeSelection.length === allStores.length ? 'Deselect all' : 'Select all'}
                            onClick={() => {
                                if (storeSelection.length === allStores.length) {
                                    return handleUnSelectAll(undefined);
                                } else {
                                    return handleSelectAll(undefined);
                                }
                            }}
                        />
                    </div>
                </div>

                <Accordion className={styles.accordion} allowZeroExpanded allowMultipleExpanded>
                    {storeCategories.map((category: StoreCategory, index) => {
                        return (
                            <AccordionItem key={index}>
                                <div className={styles.section}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={styles.accordion__button}>
                                            <div className={styles.accordionBtn}>
                                                <h2 className={styles.sectionLabel}>
                                                    {category}
                                                    &nbsp;
                                                    {numberOfTypeSelected(category) > 0 ? (
                                                        <>({numberOfTypeSelected(category)})&nbsp;</>
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </h2>
                                                <Arrow className={styles.arrow} />
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </div>
                                <AccordionItemPanel className={styles.accordion__panel}>
                                    <div className={styles.selectAllRow}>
                                        <SelectAll
                                            text={allStoresOfTypeAreSelected(category) ? 'Deselect all' : 'Select all'}
                                            onClick={() => {
                                                if (allStoresOfTypeAreSelected(category)) {
                                                    return handleUnSelectAll(category);
                                                } else {
                                                    return handleSelectAll(category);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={styles.checkboxes}>
                                        {_.filter(allStores, (store: Store) => store.category === category).map(
                                            (store: Store, index) => (
                                                <Checkbox
                                                    onClick={() => handleSelectClick(store)}
                                                    isSelected={storeSelection.includes(store)}
                                                    key={index}
                                                    storeName={store.name}
                                                />
                                            ),
                                        )}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
                <div className={styles.section}>
                    <button onClick={handleLaunchSearch} className={styles.submitBtn}>
                        Send me the result
                    </button>
                    {errorMessage ? <h1 className={styles.errorMessage}>{errorMessage}</h1> : null}
                </div>
            </div>
        </div>
    );
}

export default Search;
