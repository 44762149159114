import { inject, injectable } from 'inversify';
import DependencyType from '../DependencyType';
import { ApolloClient, NormalizedCacheObject, ObservableQuery } from '@apollo/client';
import {
    LaunchSearchDocument,
    LaunchSearchMutationVariables,
    LaunchSearchMutation,
    StoreFinderSearchProgressQueryVariables,
    StoreFinderSearchProgressQuery,
    StoreFinderSearchProgressDocument,
    StoreFinderSearchCombinedResultsQueryVariables,
    StoreFinderSearchCombinedResultsDocument,
    StoreFinderSearchCombinedResultsQuery,
    WappalyzerQueryCombinedResults,
    CombinedResultsResponse, DownloadCsvQueryVariables, DownloadCsvDocument, DownloadCsvQuery,
} from '../../graphql/generated_types';

@injectable()
export class SearchService {
    @inject(DependencyType.ApolloClient)
    private apolloClient!: ApolloClient<NormalizedCacheObject>;

    constructor() {}

    async launchSearch(variables: LaunchSearchMutationVariables): Promise<string> {
        const { data } = await this.apolloClient.mutate<LaunchSearchMutation>({
            mutation: LaunchSearchDocument,
            variables,
        });

        if (!data) throw new Error('Unable to launch search');

        return data.launchSearch;
    }

    async storeFinderSearchProgress(variables: StoreFinderSearchProgressQueryVariables): Promise<ObservableQuery> {
        const observableQuery = this.apolloClient.watchQuery<StoreFinderSearchProgressQuery>({
            query: StoreFinderSearchProgressDocument,
            variables,
            pollInterval: 5000,
        });

        if (!observableQuery) throw new Error('Unable to make query');

        return observableQuery;
    }

    async downloadCSV(variables: DownloadCsvQueryVariables): Promise<string> {
        const { data } = await this.apolloClient.query<
            DownloadCsvQuery,
            DownloadCsvQueryVariables
            >({
            fetchPolicy: 'network-only',
            query: DownloadCsvDocument,
            variables,
        });

        return data.storeFinderCSVData;
    }

    async loadSearchHistory(variables: StoreFinderSearchCombinedResultsQueryVariables): Promise<
        { __typename?: 'Query' } & {
            storeFinderSearchCombinedResults: { __typename?: 'CombinedResultsResponse' } & Pick<
                CombinedResultsResponse,
                'totalCount'
            > & {
                    results: Array<
                        { __typename?: 'WappalyzerQueryCombinedResults' } & Pick<
                            WappalyzerQueryCombinedResults,
                            | 'locationName'
                            | 'queryDate'
                            | 'numberOfSearchResults'
                            | 'storeFinderSearchId'
                            | 'storeTypes'
                            | 'maxResultTypes'
                            | 'searchRadius'
                            | 'numberOfMatchingProvidersFound'
                            | 'csvData'
                            | 'numberOfEcommerceResults'
                            | 'email'
                        >
                    >;
                };
        }
    > {
        const { data } = await this.apolloClient.query<
            StoreFinderSearchCombinedResultsQuery,
            StoreFinderSearchCombinedResultsQueryVariables
        >({
            fetchPolicy: 'network-only',
            query: StoreFinderSearchCombinedResultsDocument,
            variables,
        });

        if (!data) throw new Error('Unable to load search history');

        return data;
    }
}
