import { useParams } from 'react-router-dom';
import { ReactComponent as Mail } from '../../assets/svg/mail.svg';
import { ReactComponent as Trolley } from '../../assets/svg/grocery_store_24px_rounded.svg';
import React from 'react';
import { useInjection } from '../../di/DependencyContext';
import DependencyType from '../../di/DependencyType';
import { SearchService } from '../../di/services/SearchService';
import styles from './Progress.module.css';

export default function Progress() {
    const { id } = useParams<{ id: string }>();
    const [progress, setProgress] = React.useState<number>(-1);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const searchService = useInjection<SearchService>(DependencyType.SearchService);

    searchService
        .storeFinderSearchProgress({
            searchId: id,
        })
        .then(res =>
            res.subscribe({
                next: value => {
                    if (value.data.storeFinderSearchProgress.length > 3) {
                        setErrorMessage(value.data.storeFinderSearchProgress);
                    } else {
                        setProgress(Number(value.data.storeFinderSearchProgress));
                    }
                },
                error: error => {
                    console.log(error);
                },
            }),
        );

    if (!errorMessage && progress < 0) return <p>{''}</p>;

    if (errorMessage)
        return (
            //we received a 'Search Failed' Message, display in the UI
            <div className="container">
                <div className="wrapper flex-center">
                    <div className={styles.resultWrapper}>
                        <h1>{errorMessage}</h1>
                    </div>
                </div>
            </div>
        );

    return (
        <div className="container">
            <div className="wrapper flex-center">
                <div className={styles.resultWrapper}>
                    {progress === 100 ? (
                        <>
                            <Mail />
                            <h1 className={styles.headline}>Done! Check your email.</h1>
                        </>
                    ) : (
                        <>
                            <h1 className={styles.percent}>{progress}%</h1>
                            <h1 className={styles.progressHeadline}>
                                You can close your browser and carry on with your life while this is processing.
                            </h1>
                            <div className={styles.trolleyContainer}>
                                <Trolley
                                    style={{ left: progress - 6 > 0 ? progress - 6 + '%' : '0px' }}
                                    className={styles.trolley}
                                />
                            </div>
                            <div className={styles.barContainer}>
                                <div style={{ width: progress + '%' }} className={styles.bar}></div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
