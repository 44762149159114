import { Container } from 'inversify';
import 'reflect-metadata';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import DependencyType from './DependencyType';
import { buildApolloClient } from './factories/ApolloClientFactory';
import { SearchService } from './services/SearchService';
import { AuthService } from './services/AuthService';




export const dependenciesContainer = new Container();

dependenciesContainer
    .bind<ApolloClient<NormalizedCacheObject>>(DependencyType.ApolloClient)
    .toConstantValue(buildApolloClient());

dependenciesContainer.bind<AuthService>(DependencyType.AuthService).to(AuthService).inSingletonScope();

dependenciesContainer.bind<SearchService>(DependencyType.SearchService).to(SearchService).inSingletonScope();
