import styles from './SelectAll.module.css';

export interface SelectAllProps {
    onClick: () => void;
    text: string;
}

const SelectAll: React.FC<SelectAllProps> = props => {
    return (
        <h2 onClick={props.onClick} className={styles.selectAll}>
            {props.text}
        </h2>
    );
};

export default SelectAll;
