import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AttributeValue = {
  __typename?: 'AttributeValue';
  categoryIds: Array<Scalars['String']>;
  value: Scalars['String'];
};

export type AttributeValueInput = {
  categoryIds: Array<Scalars['String']>;
  value: Scalars['String'];
};

export type AttributeValueOverride = {
  __typename?: 'AttributeValueOverride';
  displayValue: Scalars['String'];
  originalValue: Scalars['String'];
};

export type AttributeValueOverrideInput = {
  displayValue: Scalars['String'];
  originalValue: Scalars['String'];
};

export enum CapitalisationStyle {
  Capitalised = 'CAPITALISED',
  Original = 'ORIGINAL',
  Uppercase = 'UPPERCASE'
}

export type CategoriesResponse = {
  __typename?: 'CategoriesResponse';
  edges?: Maybe<Array<CategoryEdge>>;
  pageInfo?: Maybe<CategoryPageInfo>;
  totalCount: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  handle: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  overrides: Array<CategoryOverride>;
  priority: Scalars['Float'];
  productCount: Scalars['Int'];
  shopifyId: Scalars['String'];
  shopifyStorefrontId: Scalars['String'];
  synchronising: SyncStatus;
  title: Scalars['String'];
};


export type CategoryOverridesArgs = {
  cloudshelfId?: Maybe<Scalars['ID']>;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Category>;
};

export type CategoryOverride = {
  __typename?: 'CategoryOverride';
  cloudshelf: Cloudshelf;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  priority: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  useImage: Scalars['Boolean'];
};

export type CategoryPageInfo = {
  __typename?: 'CategoryPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Checkout = {
  __typename?: 'Checkout';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CheckoutBasketItemInput = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CheckoutCreateInput = {
  cloudshelfId: Scalars['ID'];
  items: Array<CheckoutBasketItemInput>;
  token: Scalars['String'];
};

export type Cloudshelf = {
  __typename?: 'Cloudshelf';
  actions: CloudshelfActions;
  categories: Array<Category>;
  checkouts?: Maybe<Array<Checkout>>;
  config: CloudshelfConfig;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  devices: Array<Device>;
  engagements: Array<Session>;
  filters?: Maybe<Array<Filter>>;
  id: Scalars['ID'];
  owner: CloudshelfUser;
  updatedAt: Scalars['DateTime'];
};


export type CloudshelfEngagementsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type CloudshelfActions = {
  __typename?: 'CloudshelfActions';
  isCTACompleted: Scalars['Boolean'];
  isCollectionsCompleted: Scalars['Boolean'];
  isConfettiShown: Scalars['Boolean'];
  isDiscountingCompleted: Scalars['Boolean'];
  isFiltersCompleted: Scalars['Boolean'];
  isNameCompleted: Scalars['Boolean'];
  isPreviewed: Scalars['Boolean'];
  isProductDetailsCompleted: Scalars['Boolean'];
  isStockCompleted: Scalars['Boolean'];
  isThemeCompleted: Scalars['Boolean'];
};

export type CloudshelfCategoryInput = {
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  useImage: Scalars['Boolean'];
};

export type CloudshelfConfig = {
  __typename?: 'CloudshelfConfig';
  categories: Array<Category>;
  cloudshelf: Cloudshelf;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  displaySKU: Scalars['Boolean'];
  displayVendor: Scalars['Boolean'];
  id: Scalars['ID'];
  inactivityTimeout: Scalars['Float'];
  includeProductsInStock: Scalars['Boolean'];
  includeProductsLimitedAvailability: Scalars['Boolean'];
  includeProductsOutOfStock: Scalars['Boolean'];
  name: Scalars['String'];
  normalizedName: Scalars['String'];
  providerConfig: CloudshelfProviderConfig;
  subdomain: Scalars['String'];
  theme: CloudshelfTheme;
  trackers: Array<CloudshelfTracker>;
  updatedAt: Scalars['DateTime'];
};

export type CloudshelfConfigResult = {
  __typename?: 'CloudshelfConfigResult';
  config?: Maybe<CloudshelfConfig>;
  status: CloudshelfConfigStatus;
};

export enum CloudshelfConfigStatus {
  CloudshelfPreview = 'CLOUDSHELF_PREVIEW',
  DeviceNoCloudshelf = 'DEVICE_NO_CLOUDSHELF',
  DeviceRemoved = 'DEVICE_REMOVED',
  DeviceWithCloudshelf = 'DEVICE_WITH_CLOUDSHELF',
  Notfound = 'NOTFOUND'
}

export type CloudshelfDetailsInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CloudshelfEdge = {
  __typename?: 'CloudshelfEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Cloudshelf>;
};

export type CloudshelfPageInfo = {
  __typename?: 'CloudshelfPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CloudshelfProductOptionsInput = {
  displaySKU: Scalars['Boolean'];
  displayVendor: Scalars['Boolean'];
};

export type CloudshelfProviderConfig = {
  __typename?: 'CloudshelfProviderConfig';
  eCommerceProvider: ECommerceProvider;
  item1?: Maybe<Scalars['String']>;
  item2?: Maybe<Scalars['String']>;
};

export type CloudshelfStockOptionsInput = {
  includeProductsInStock: Scalars['Boolean'];
  includeProductsLimitedAvailability: Scalars['Boolean'];
  includeProductsOutOfStock: Scalars['Boolean'];
};

export type CloudshelfTheme = {
  __typename?: 'CloudshelfTheme';
  callToAction: Scalars['String'];
  config: CloudshelfConfig;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  primaryColor: Scalars['String'];
};

export type CloudshelfTracker = {
  __typename?: 'CloudshelfTracker';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CloudshelfUser = {
  __typename?: 'CloudshelfUser';
  cloudshelves?: Maybe<Array<Cloudshelf>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  devices: Array<Device>;
  eCommerceProvider: ECommerceProvider;
  eCommerceProviderIdentifier?: Maybe<Scalars['String']>;
  eCommerceProviderPermissionsCheckInfo: Scalars['String'];
  eCommerceProviderPermissionsCheckPending: Scalars['Boolean'];
  eCommerceProviderPermissionsMissing: Scalars['Boolean'];
  email: Scalars['String'];
  firebaseId: Scalars['ID'];
  hasData: Scalars['Boolean'];
  id: Scalars['ID'];
  keycloakId?: Maybe<Scalars['ID']>;
  locations: Array<Location>;
  mfaAuths?: Maybe<Array<MfaAuth>>;
  name: Scalars['String'];
  originalEmail?: Maybe<Scalars['String']>;
  shopifyStore?: Maybe<ShopifyStore>;
  subdomain?: Maybe<Scalars['String']>;
  synchronising: SyncStatus;
  updatedAt: Scalars['DateTime'];
  userType: CloudshelfUserType;
};

export type CloudshelfUserEdge = {
  __typename?: 'CloudshelfUserEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CloudshelfUser>;
};

export type CloudshelfUserPageInfo = {
  __typename?: 'CloudshelfUserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum CloudshelfUserType {
  PrivateRetailer = 'PrivateRetailer',
  ShopifyStoreRetailer = 'ShopifyStoreRetailer',
  SuperAdmin = 'SuperAdmin',
  SupportTeam = 'SupportTeam',
  Unknown = 'Unknown'
}

export type CloudshelfUsersResponse = {
  __typename?: 'CloudshelfUsersResponse';
  edges?: Maybe<Array<CloudshelfUserEdge>>;
  pageInfo?: Maybe<CloudshelfUserPageInfo>;
  totalCount: Scalars['Int'];
};

export type CloudshelvesResponse = {
  __typename?: 'CloudshelvesResponse';
  edges?: Maybe<Array<CloudshelfEdge>>;
  pageInfo?: Maybe<CloudshelfPageInfo>;
  totalCount: Scalars['Int'];
};

export type CombinedResultsResponse = {
  __typename?: 'CombinedResultsResponse';
  results: Array<WappalyzerQueryCombinedResults>;
  totalCount: Scalars['Int'];
};

export type CreateCloudshelfConfigInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  productOptions: CloudshelfProductOptionsInput;
  theme: CreateCloudshelfThemeInput;
};

export type CreateCloudshelfInput = {
  categories?: Maybe<Array<CloudshelfCategoryInput>>;
  config: CreateCloudshelfConfigInput;
};

export type CreateCloudshelfThemeInput = {
  callToAction: Scalars['String'];
  primaryColor: Scalars['String'];
};

export type CreateRetailerInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  provider: Scalars['String'];
  shopifyStore: ShopifyRetailerInput;
};

export type CreateTeamMemberInput = {
  admin: Scalars['Boolean'];
  email: Scalars['String'];
  name: Scalars['String'];
};


export type Device = {
  __typename?: 'Device';
  cloudshelf?: Maybe<Cloudshelf>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  engineInfo: Scalars['String'];
  id: Scalars['ID'];
  info: Scalars['String'];
  lastPing?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  orientationInfo: DeviceOrientation;
  owner?: Maybe<CloudshelfUser>;
  registered: Scalars['Boolean'];
  sizeInfo: Scalars['String'];
  status: DeviceStatus;
  tags: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uptimeRecords: Array<Uptime>;
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Device>;
};

export enum DeviceOrientation {
  Horizontal = 'HORIZONTAL',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE',
  Unknown = 'UNKNOWN'
}

export type DevicePageInfo = {
  __typename?: 'DevicePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum DeviceStatus {
  Live = 'LIVE',
  NoCloudshelf = 'NO_CLOUDSHELF',
  Offline = 'OFFLINE'
}

export type DevicesResponse = {
  __typename?: 'DevicesResponse';
  edges?: Maybe<Array<DeviceEdge>>;
  pageInfo?: Maybe<DevicePageInfo>;
  totalCount: Scalars['Int'];
};

export type Filter = {
  __typename?: 'Filter';
  attributeValues: Array<AttributeValue>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  ecommProviderFieldName: Scalars['String'];
  expandedByDefault: Scalars['Boolean'];
  hiddenAttributeValues: Array<Scalars['String']>;
  id: Scalars['ID'];
  isHidden: Scalars['Boolean'];
  options?: Maybe<FilterOptions>;
  priority: Scalars['Float'];
  type: Scalars['String'];
  valueOverrides: Array<AttributeValueOverride>;
};

export type FilterInput = {
  attributeValues: Array<AttributeValueInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  ecommProviderFieldName: Scalars['String'];
  expandedByDefault?: Maybe<Scalars['Boolean']>;
  hiddenAttributeValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isHidden?: Maybe<Scalars['Boolean']>;
  options?: Maybe<FilterOptionsInput>;
  priority?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  valueOverrides?: Maybe<Array<AttributeValueOverrideInput>>;
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  baseShoesizeUnit?: Maybe<Scalars['String']>;
  capitalisationStyle?: Maybe<CapitalisationStyle>;
  orderType: FilterOrderType;
  swatches?: Maybe<Array<Swatch>>;
};

export type FilterOptionsInput = {
  baseShoesizeUnit?: Maybe<Scalars['String']>;
  capitalisationStyle?: Maybe<CapitalisationStyle>;
  orderType: FilterOrderType;
  swatches?: Maybe<Array<SwatchInput>>;
};

export enum FilterOrderType {
  Alphabetical = 'ALPHABETICAL',
  Ascending = 'ASCENDING',
  Chromatic = 'CHROMATIC',
  Custom = 'CUSTOM',
  Descending = 'DESCENDING'
}

export enum FilterType {
  Basic = 'BASIC',
  CategoryHandle = 'CATEGORY_HANDLE',
  CategoryId = 'CATEGORY_ID',
  Colour = 'COLOUR',
  Material = 'MATERIAL',
  Price = 'PRICE',
  ProductHandle = 'PRODUCT_HANDLE',
  ProductTitle = 'PRODUCT_TITLE',
  ProductType = 'PRODUCT_TYPE',
  Promotions = 'PROMOTIONS',
  ShoeSize = 'SHOE_SIZE',
  Size = 'SIZE',
  StockLevel = 'STOCK_LEVEL',
  Tag = 'TAG',
  Vendor = 'VENDOR'
}

export type Identifier = {
  __typename?: 'Identifier';
  id: Scalars['ID'];
};

export type IdentifierEdge = {
  __typename?: 'IdentifierEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Identifier>;
};

export type IdentifierPageInfo = {
  __typename?: 'IdentifierPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type KnownVersion = {
  __typename?: 'KnownVersion';
  versionString: Scalars['String'];
  versionType: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  allowSelection: Scalars['Boolean'];
  contactDetail: Scalars['String'];
  createdAt: Scalars['DateTime'];
  devices: Array<Device>;
  eCommerceProvider: ECommerceProvider;
  eCommerceProviderIdentifier: Scalars['String'];
  formattedAddress: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: CloudshelfUser;
  updatedAt: Scalars['DateTime'];
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Location>;
};

export type LocationPageInfo = {
  __typename?: 'LocationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type LocationsResponse = {
  __typename?: 'LocationsResponse';
  edges?: Maybe<Array<LocationEdge>>;
  pageInfo?: Maybe<LocationPageInfo>;
  totalCount: Scalars['Int'];
};

export type MfaAuth = {
  __typename?: 'MFAAuth';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: CloudshelfUser;
  updatedAt: Scalars['DateTime'];
};

export type MfaCreateResponse = {
  __typename?: 'MFACreateResponse';
  qr: Scalars['String'];
  secret: Scalars['String'];
  uri: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCheckout: Scalars['String'];
  createCloudshelf: Cloudshelf;
  createNewCloudshelf: Cloudshelf;
  createRetailer: CloudshelfUser;
  createTeamMember: CloudshelfUser;
  deleteCloudshelfUsers: Scalars['Boolean'];
  deleteCloudshelves: Scalars['Boolean'];
  deleteDevices: Scalars['Boolean'];
  deleteOldProdWebhooks: Scalars['Boolean'];
  deleteSession: Scalars['Boolean'];
  duplicateCloudshelves: Array<Cloudshelf>;
  endSession: Session;
  forceSyncAllLocations: Scalars['Boolean'];
  forceWebhookRecreation: Scalars['Boolean'];
  generateMFARequest: Scalars['String'];
  generateMFASecret: MfaCreateResponse;
  installShopifyHooks: Scalars['Boolean'];
  launchSearch: Scalars['ID'];
  launchWappalyzerQuery: Scalars['Boolean'];
  logWebhooks: Scalars['Boolean'];
  newSession: Session;
  pairDevice: Device;
  pauseBullQueue: Scalars['Boolean'];
  recheckPermissions: Scalars['Boolean'];
  resumeBullQueue: Scalars['Boolean'];
  runPostUpdateFullSync: Scalars['String'];
  runPostUpdatePermissionChecks: Scalars['String'];
  runPostUpdateWebhooksTool: Scalars['String'];
  scheduleBullLoadTest: Scalars['Boolean'];
  sendResetCloudshelfUsersPasswordEmail: Scalars['Boolean'];
  sendResetPasswordEmail: Scalars['Boolean'];
  setActiveVersion: Scalars['Boolean'];
  setClaimsAndDisplayNameForFirebaseUid: Scalars['Boolean'];
  synchroniseCategories: Array<Category>;
  synchroniseWithCloudshelfUsersProvider: Scalars['Boolean'];
  updateCloudshelf: Cloudshelf;
  updateCloudshelfCallToAction: Cloudshelf;
  updateCloudshelfCategories: Cloudshelf;
  updateCloudshelfDetails: Cloudshelf;
  updateCloudshelfFilters: Cloudshelf;
  updateCloudshelfPreviewState: Cloudshelf;
  updateCloudshelfProductDetails: Cloudshelf;
  updateCloudshelfStockOptions: Cloudshelf;
  updateCloudshelfTheme: Cloudshelf;
  updateDeviceCloudshelf: Device;
  updateDeviceDetails: Device;
  updateDeviceLocation: Device;
  uptimePing: Scalars['Boolean'];
  verifyMFARequest: Scalars['String'];
};


export type MutationCreateCheckoutArgs = {
  checkoutCreateInput: CheckoutCreateInput;
};


export type MutationCreateCloudshelfArgs = {
  cloudshelf: CreateCloudshelfInput;
  cloudshelfUserId?: Maybe<Scalars['ID']>;
};


export type MutationCreateNewCloudshelfArgs = {
  forUserId?: Maybe<Scalars['String']>;
  wizardGenerated: Scalars['Boolean'];
};


export type MutationCreateRetailerArgs = {
  input: CreateRetailerInput;
};


export type MutationCreateTeamMemberArgs = {
  input: CreateTeamMemberInput;
};


export type MutationDeleteCloudshelfUsersArgs = {
  cloudshelfUserIds: Array<Scalars['ID']>;
};


export type MutationDeleteCloudshelvesArgs = {
  cloudshelfIds: Array<Scalars['ID']>;
};


export type MutationDeleteDevicesArgs = {
  deviceIds: Array<Scalars['ID']>;
};


export type MutationDeleteOldProdWebhooksArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationDeleteSessionArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateCloudshelvesArgs = {
  cloudshelfIds: Array<Scalars['ID']>;
};


export type MutationEndSessionArgs = {
  id: Scalars['ID'];
  interactions: Scalars['Int'];
};


export type MutationForceWebhookRecreationArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationInstallShopifyHooksArgs = {
  storeId: Scalars['String'];
};


export type MutationLaunchSearchArgs = {
  searchInput: SearchInput;
};


export type MutationLaunchWappalyzerQueryArgs = {
  queryInput: QueryInput;
};


export type MutationLogWebhooksArgs = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
};


export type MutationNewSessionArgs = {
  deviceId: Scalars['ID'];
};


export type MutationPairDeviceArgs = {
  forUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pairingCode: Scalars['String'];
};


export type MutationRecheckPermissionsArgs = {
  cloudshelfUserId?: Maybe<Scalars['ID']>;
};


export type MutationSendResetCloudshelfUsersPasswordEmailArgs = {
  cloudshelfUserIds: Array<Scalars['ID']>;
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetActiveVersionArgs = {
  key: Scalars['String'];
  type: VersionType;
  versionString: Scalars['String'];
};


export type MutationSetClaimsAndDisplayNameForFirebaseUidArgs = {
  claims: Array<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firebaseUid: Scalars['String'];
};


export type MutationSynchroniseWithCloudshelfUsersProviderArgs = {
  cloudshelfUserId: Scalars['ID'];
};


export type MutationUpdateCloudshelfArgs = {
  cloudshelf: UpdateCloudshelfInput;
};


export type MutationUpdateCloudshelfCallToActionArgs = {
  callToAction: Scalars['String'];
  cloudshelfId: Scalars['ID'];
};


export type MutationUpdateCloudshelfCategoriesArgs = {
  categories: Array<CloudshelfCategoryInput>;
  cloudshelfId: Scalars['ID'];
};


export type MutationUpdateCloudshelfDetailsArgs = {
  cloudshelfId: Scalars['ID'];
  details: CloudshelfDetailsInput;
};


export type MutationUpdateCloudshelfFiltersArgs = {
  cloudshelfId: Scalars['ID'];
  filters: Array<FilterInput>;
};


export type MutationUpdateCloudshelfPreviewStateArgs = {
  cloudshelfId: Scalars['ID'];
  confettiShown?: Maybe<Scalars['Boolean']>;
  isPreviewed?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCloudshelfProductDetailsArgs = {
  cloudshelfId: Scalars['ID'];
  productDetails: CloudshelfProductOptionsInput;
};


export type MutationUpdateCloudshelfStockOptionsArgs = {
  cloudshelfId: Scalars['ID'];
  stockOptions: CloudshelfStockOptionsInput;
};


export type MutationUpdateCloudshelfThemeArgs = {
  cloudshelfId: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  themeColor: Scalars['String'];
};


export type MutationUpdateDeviceCloudshelfArgs = {
  input: UpdateDeviceCloudshelfInput;
};


export type MutationUpdateDeviceDetailsArgs = {
  input: UpdateDeviceDetailsInput;
};


export type MutationUpdateDeviceLocationArgs = {
  input: UpdateDeviceLocationInput;
};


export type MutationUptimePingArgs = {
  deviceId: Scalars['ID'];
  engineVersion: Scalars['String'];
  screenHeight: Scalars['Int'];
  screenWidth: Scalars['Int'];
  userAgent: Scalars['String'];
};


export type MutationVerifyMfaRequestArgs = {
  authcode: Scalars['String'];
  requestId: Scalars['String'];
};

export type OrderInput = {
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionsResponse = {
  __typename?: 'PermissionsResponse';
  adminPermissions: Array<ShopifyPermission>;
  storefrontPermissions: Array<ShopifyPermission>;
};

export type ProductFilter = {
  options?: Maybe<Array<SelectedFilterOption>>;
};

export type ProductsSearchResponse = {
  __typename?: 'ProductsSearchResponse';
  edges?: Maybe<Array<IdentifierEdge>>;
  pageInfo?: Maybe<IdentifierPageInfo>;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  allShopifyStores: Array<ShopifyStore>;
  categories: Array<Category>;
  checkAllPermissionsForCloudshelfUser: PermissionsResponse;
  checkGrantedPermissionForCloudshelfUser: PermissionsResponse;
  checkoutUrls: Array<Checkout>;
  cloudshelf: Cloudshelf;
  cloudshelfCategories: CategoriesResponse;
  cloudshelfCategoryById: Category;
  cloudshelfUser: CloudshelfUser;
  cloudshelfUsers: CloudshelfUsersResponse;
  cloudshelves: CloudshelvesResponse;
  currentUser: CloudshelfUser;
  device: Device;
  devices: DevicesResponse;
  engagements: Array<Session>;
  getCloudshelfConfig: CloudshelfConfigResult;
  getCustomTokenFromShopifySessionToken: Scalars['String'];
  getProviderDetails?: Maybe<CloudshelfProviderConfig>;
  getSession?: Maybe<Session>;
  getVersionByType: KnownVersion;
  isCheckoutScanned: Scalars['Boolean'];
  isCloudshelfNameUsed: Scalars['Boolean'];
  location: Location;
  locations: LocationsResponse;
  productIds: ProductsSearchResponse;
  shopifyStore?: Maybe<ShopifyStore>;
  shopifyStoreById: ShopifyStore;
  shopifyStores: ShopifyStoresResponse;
  storeFinderCSVData: Scalars['String'];
  storeFinderSearchCombinedResults: CombinedResultsResponse;
  storeFinderSearchProgress: Scalars['String'];
  uptimeRecords: Array<UptimePercentageTotalResponse>;
};


export type QueryCategoriesArgs = {
  forUserId?: Maybe<Scalars['String']>;
};


export type QueryCheckAllPermissionsForCloudshelfUserArgs = {
  retailerId: Scalars['ID'];
};


export type QueryCheckGrantedPermissionForCloudshelfUserArgs = {
  retailerId: Scalars['ID'];
};


export type QueryCheckoutUrlsArgs = {
  cloudshelfId: Array<Scalars['ID']>;
};


export type QueryCloudshelfArgs = {
  cloudshelfId: Scalars['ID'];
};


export type QueryCloudshelfCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cloudshelfId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCloudshelfCategoryByIdArgs = {
  categoryId: Scalars['String'];
};


export type QueryCloudshelfUserArgs = {
  cloudshelfUserId: Scalars['ID'];
};


export type QueryCloudshelfUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<UserListOrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
  userTypes: Array<CloudshelfUserType>;
};


export type QueryCloudshelvesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryCurrentUserArgs = {
  cloudshelfUserId?: Maybe<Scalars['ID']>;
};


export type QueryDeviceArgs = {
  deviceId: Scalars['String'];
};


export type QueryDevicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryEngagementsArgs = {
  cloudshelfId?: Maybe<Scalars['ID']>;
  deviceId?: Maybe<Scalars['ID']>;
  endDate: Scalars['DateTime'];
  retailerIds: Array<Scalars['ID']>;
  startDate: Scalars['DateTime'];
};


export type QueryGetCloudshelfConfigArgs = {
  cloudshelfName: Scalars['String'];
  retailerSubdomain: Scalars['String'];
};


export type QueryGetCustomTokenFromShopifySessionTokenArgs = {
  sessionToken: Scalars['String'];
};


export type QueryGetProviderDetailsArgs = {
  cloudshelfConfigId: Scalars['ID'];
  provider: ECommerceProvider;
};


export type QueryGetSessionArgs = {
  id: Scalars['ID'];
};


export type QueryGetVersionByTypeArgs = {
  type: VersionType;
};


export type QueryIsCheckoutScannedArgs = {
  id: Scalars['String'];
};


export type QueryIsCloudshelfNameUsedArgs = {
  forUserId?: Maybe<Scalars['String']>;
  ignoreCloudshelfId: Scalars['ID'];
  name: Scalars['String'];
};


export type QueryLocationArgs = {
  locationId: Scalars['String'];
};


export type QueryLocationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forUserId?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderInput>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryProductIdsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cloudshelfId: Scalars['ID'];
  filter: ProductFilter;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShopifyStoreByIdArgs = {
  storeId: Scalars['ID'];
};


export type QueryShopifyStoresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStoreFinderCsvDataArgs = {
  storeFinderSearchId?: Maybe<Scalars['String']>;
};


export type QueryStoreFinderSearchCombinedResultsArgs = {
  amountToSkip: Scalars['Float'];
  searchTerm: Scalars['String'];
};


export type QueryStoreFinderSearchProgressArgs = {
  storeFinderSearchId: Scalars['String'];
};


export type QueryUptimeRecordsArgs = {
  date: Scalars['DateTime'];
  retailerId?: Maybe<Scalars['ID']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type QueryInput = {
  email: Scalars['String'];
  storeFinderSearchId: Scalars['String'];
};

export type SearchInput = {
  coordinates: Scalars['String'];
  email: Scalars['String'];
  locationString: Scalars['String'];
  radius: Scalars['Float'];
  storeTypes: Array<Scalars['String']>;
};

export type SelectedFilterOption = {
  ecommProviderName: Scalars['String'];
  filterType: FilterType;
  selectedValues: Array<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Float'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  interactions: Scalars['Float'];
  isComplete: Scalars['Boolean'];
  isInvalid: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ShopifyPermission = {
  __typename?: 'ShopifyPermission';
  handle: Scalars['String'];
  level: Scalars['String'];
};

export type ShopifyRetailerInput = {
  accessToken: Scalars['String'];
  domain: Scalars['String'];
  sharedSecret: Scalars['String'];
};

export type ShopifyStore = {
  __typename?: 'ShopifyStore';
  categories?: Maybe<Array<Category>>;
  cloudshelves?: Maybe<Array<Cloudshelf>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  domain: Scalars['String'];
  email: Scalars['String'];
  hasCorrectScopes: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: CloudshelfUser;
  updatedAt: Scalars['DateTime'];
};

export type ShopifyStoreEdge = {
  __typename?: 'ShopifyStoreEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ShopifyStore>;
};

export type ShopifyStorePageInfo = {
  __typename?: 'ShopifyStorePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ShopifyStoresResponse = {
  __typename?: 'ShopifyStoresResponse';
  edges?: Maybe<Array<ShopifyStoreEdge>>;
  pageInfo?: Maybe<ShopifyStorePageInfo>;
  totalCount: Scalars['Int'];
};

/** Allow ordering a query's results */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Swatch = {
  __typename?: 'Swatch';
  displayName: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type SwatchInput = {
  displayName: Scalars['String'];
  imageUrl: Scalars['String'];
};

export enum SyncStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Syncing = 'SYNCING'
}

export type UpdateCloudshelfConfigInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  productOptions: CloudshelfProductOptionsInput;
  theme: UpdateCloudshelfThemeInput;
};

export type UpdateCloudshelfInput = {
  categories?: Maybe<Array<CloudshelfCategoryInput>>;
  config: UpdateCloudshelfConfigInput;
  filters?: Maybe<Array<FilterInput>>;
  id: Scalars['ID'];
};

export type UpdateCloudshelfThemeInput = {
  callToAction: Scalars['String'];
  id: Scalars['ID'];
  primaryColor: Scalars['String'];
};

export type UpdateDeviceCloudshelfInput = {
  cloudshelfId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateDeviceDetailsInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateDeviceLocationInput = {
  id: Scalars['ID'];
  locationId?: Maybe<Scalars['String']>;
};

export type Uptime = {
  __typename?: 'Uptime';
  count: Scalars['Float'];
  date: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  device: Device;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type UptimePercentageResponse = {
  __typename?: 'UptimePercentageResponse';
  date: Scalars['DateTime'];
  percentage: Scalars['Float'];
};

export type UptimePercentageTotalResponse = {
  __typename?: 'UptimePercentageTotalResponse';
  averagePercentage: Scalars['Float'];
  deviceName: Scalars['String'];
  records: Array<UptimePercentageResponse>;
};

export type UserListOrderInput = {
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum VersionType {
  Backend = 'BACKEND',
  Engine = 'ENGINE',
  Manager = 'MANAGER',
  Storefinder = 'STOREFINDER',
  Worker = 'WORKER'
}

export type WappalyzerQueryCombinedResults = {
  __typename?: 'WappalyzerQueryCombinedResults';
  createdAt: Scalars['DateTime'];
  csvData: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  locationName: Scalars['String'];
  maxResultTypes: Array<Scalars['String']>;
  numberOfEcommerceResults: Scalars['Float'];
  numberOfMatchingProvidersFound: Scalars['Float'];
  numberOfSearchResults: Scalars['Float'];
  queryDate: Scalars['DateTime'];
  queryStatus: Scalars['String'];
  searchCoordinates: Scalars['String'];
  searchRadius: Scalars['Float'];
  storeFinderSearchId: Scalars['String'];
  storeTypes: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  wappalyzerQueryId: Scalars['String'];
};

export enum ECommerceProvider {
  None = 'None',
  Shopify = 'Shopify'
}

export type LaunchSearchMutationVariables = Exact<{
  storeTypes: Array<Scalars['String']> | Scalars['String'];
  email: Scalars['String'];
  radius: Scalars['Float'];
  coordinates: Scalars['String'];
  locationString: Scalars['String'];
}>;


export type LaunchSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'launchSearch'>
);

export type GetCustomTokenFromShopifySessionTokenQueryVariables = Exact<{
  sessionToken: Scalars['String'];
}>;


export type GetCustomTokenFromShopifySessionTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCustomTokenFromShopifySessionToken'>
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetPasswordEmail'>
);

export type SendResetCloudshelfUsersPasswordEmailMutationVariables = Exact<{
  cloudshelfUserIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SendResetCloudshelfUsersPasswordEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetCloudshelfUsersPasswordEmail'>
);

export type GenerateMfaRequestMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateMfaRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateMFARequest'>
);

export type VerifyMfaRequestMutationVariables = Exact<{
  authcode: Scalars['String'];
  requestId: Scalars['String'];
}>;


export type VerifyMfaRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyMFARequest'>
);

export type GenerateMfaSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateMfaSecretMutation = (
  { __typename?: 'Mutation' }
  & { generateMFASecret: (
    { __typename?: 'MFACreateResponse' }
    & Pick<MfaCreateResponse, 'secret' | 'uri' | 'qr'>
  ) }
);

export type DownloadCsvQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type DownloadCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'storeFinderCSVData'>
);

export type StoreFinderSearchCombinedResultsQueryVariables = Exact<{
  amountToSkip: Scalars['Float'];
  searchTerm: Scalars['String'];
}>;


export type StoreFinderSearchCombinedResultsQuery = (
  { __typename?: 'Query' }
  & { storeFinderSearchCombinedResults: (
    { __typename?: 'CombinedResultsResponse' }
    & Pick<CombinedResultsResponse, 'totalCount'>
    & { results: Array<(
      { __typename?: 'WappalyzerQueryCombinedResults' }
      & Pick<WappalyzerQueryCombinedResults, 'locationName' | 'queryDate' | 'numberOfSearchResults' | 'storeFinderSearchId' | 'storeTypes' | 'maxResultTypes' | 'searchRadius' | 'numberOfMatchingProvidersFound' | 'numberOfEcommerceResults' | 'csvData' | 'email'>
    )> }
  ) }
);

export type StoreFinderSearchProgressQueryVariables = Exact<{
  searchId: Scalars['String'];
}>;


export type StoreFinderSearchProgressQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'storeFinderSearchProgress'>
);


export const LaunchSearchDocument = gql`
    mutation launchSearch($storeTypes: [String!]!, $email: String!, $radius: Float!, $coordinates: String!, $locationString: String!) {
  launchSearch(
    searchInput: {storeTypes: $storeTypes, email: $email, radius: $radius, coordinates: $coordinates, locationString: $locationString}
  )
}
    `;
export type LaunchSearchMutationFn = Apollo.MutationFunction<LaunchSearchMutation, LaunchSearchMutationVariables>;

/**
 * __useLaunchSearchMutation__
 *
 * To run a mutation, you first call `useLaunchSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchSearchMutation, { data, loading, error }] = useLaunchSearchMutation({
 *   variables: {
 *      storeTypes: // value for 'storeTypes'
 *      email: // value for 'email'
 *      radius: // value for 'radius'
 *      coordinates: // value for 'coordinates'
 *      locationString: // value for 'locationString'
 *   },
 * });
 */
export function useLaunchSearchMutation(baseOptions?: Apollo.MutationHookOptions<LaunchSearchMutation, LaunchSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LaunchSearchMutation, LaunchSearchMutationVariables>(LaunchSearchDocument, options);
      }
export type LaunchSearchMutationHookResult = ReturnType<typeof useLaunchSearchMutation>;
export type LaunchSearchMutationResult = Apollo.MutationResult<LaunchSearchMutation>;
export type LaunchSearchMutationOptions = Apollo.BaseMutationOptions<LaunchSearchMutation, LaunchSearchMutationVariables>;
export const GetCustomTokenFromShopifySessionTokenDocument = gql`
    query getCustomTokenFromShopifySessionToken($sessionToken: String!) {
  getCustomTokenFromShopifySessionToken(sessionToken: $sessionToken)
}
    `;

/**
 * __useGetCustomTokenFromShopifySessionTokenQuery__
 *
 * To run a query within a React component, call `useGetCustomTokenFromShopifySessionTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTokenFromShopifySessionTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTokenFromShopifySessionTokenQuery({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetCustomTokenFromShopifySessionTokenQuery(baseOptions: Apollo.QueryHookOptions<GetCustomTokenFromShopifySessionTokenQuery, GetCustomTokenFromShopifySessionTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomTokenFromShopifySessionTokenQuery, GetCustomTokenFromShopifySessionTokenQueryVariables>(GetCustomTokenFromShopifySessionTokenDocument, options);
      }
export function useGetCustomTokenFromShopifySessionTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomTokenFromShopifySessionTokenQuery, GetCustomTokenFromShopifySessionTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomTokenFromShopifySessionTokenQuery, GetCustomTokenFromShopifySessionTokenQueryVariables>(GetCustomTokenFromShopifySessionTokenDocument, options);
        }
export type GetCustomTokenFromShopifySessionTokenQueryHookResult = ReturnType<typeof useGetCustomTokenFromShopifySessionTokenQuery>;
export type GetCustomTokenFromShopifySessionTokenLazyQueryHookResult = ReturnType<typeof useGetCustomTokenFromShopifySessionTokenLazyQuery>;
export type GetCustomTokenFromShopifySessionTokenQueryResult = Apollo.QueryResult<GetCustomTokenFromShopifySessionTokenQuery, GetCustomTokenFromShopifySessionTokenQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  sendResetPasswordEmail(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendResetCloudshelfUsersPasswordEmailDocument = gql`
    mutation sendResetCloudshelfUsersPasswordEmail($cloudshelfUserIds: [ID!]!) {
  sendResetCloudshelfUsersPasswordEmail(cloudshelfUserIds: $cloudshelfUserIds)
}
    `;
export type SendResetCloudshelfUsersPasswordEmailMutationFn = Apollo.MutationFunction<SendResetCloudshelfUsersPasswordEmailMutation, SendResetCloudshelfUsersPasswordEmailMutationVariables>;

/**
 * __useSendResetCloudshelfUsersPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetCloudshelfUsersPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetCloudshelfUsersPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetCloudshelfUsersPasswordEmailMutation, { data, loading, error }] = useSendResetCloudshelfUsersPasswordEmailMutation({
 *   variables: {
 *      cloudshelfUserIds: // value for 'cloudshelfUserIds'
 *   },
 * });
 */
export function useSendResetCloudshelfUsersPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetCloudshelfUsersPasswordEmailMutation, SendResetCloudshelfUsersPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetCloudshelfUsersPasswordEmailMutation, SendResetCloudshelfUsersPasswordEmailMutationVariables>(SendResetCloudshelfUsersPasswordEmailDocument, options);
      }
export type SendResetCloudshelfUsersPasswordEmailMutationHookResult = ReturnType<typeof useSendResetCloudshelfUsersPasswordEmailMutation>;
export type SendResetCloudshelfUsersPasswordEmailMutationResult = Apollo.MutationResult<SendResetCloudshelfUsersPasswordEmailMutation>;
export type SendResetCloudshelfUsersPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetCloudshelfUsersPasswordEmailMutation, SendResetCloudshelfUsersPasswordEmailMutationVariables>;
export const GenerateMfaRequestDocument = gql`
    mutation generateMFARequest {
  generateMFARequest
}
    `;
export type GenerateMfaRequestMutationFn = Apollo.MutationFunction<GenerateMfaRequestMutation, GenerateMfaRequestMutationVariables>;

/**
 * __useGenerateMfaRequestMutation__
 *
 * To run a mutation, you first call `useGenerateMfaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMfaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMfaRequestMutation, { data, loading, error }] = useGenerateMfaRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateMfaRequestMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMfaRequestMutation, GenerateMfaRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMfaRequestMutation, GenerateMfaRequestMutationVariables>(GenerateMfaRequestDocument, options);
      }
export type GenerateMfaRequestMutationHookResult = ReturnType<typeof useGenerateMfaRequestMutation>;
export type GenerateMfaRequestMutationResult = Apollo.MutationResult<GenerateMfaRequestMutation>;
export type GenerateMfaRequestMutationOptions = Apollo.BaseMutationOptions<GenerateMfaRequestMutation, GenerateMfaRequestMutationVariables>;
export const VerifyMfaRequestDocument = gql`
    mutation verifyMFARequest($authcode: String!, $requestId: String!) {
  verifyMFARequest(authcode: $authcode, requestId: $requestId)
}
    `;
export type VerifyMfaRequestMutationFn = Apollo.MutationFunction<VerifyMfaRequestMutation, VerifyMfaRequestMutationVariables>;

/**
 * __useVerifyMfaRequestMutation__
 *
 * To run a mutation, you first call `useVerifyMfaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMfaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMfaRequestMutation, { data, loading, error }] = useVerifyMfaRequestMutation({
 *   variables: {
 *      authcode: // value for 'authcode'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useVerifyMfaRequestMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMfaRequestMutation, VerifyMfaRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMfaRequestMutation, VerifyMfaRequestMutationVariables>(VerifyMfaRequestDocument, options);
      }
export type VerifyMfaRequestMutationHookResult = ReturnType<typeof useVerifyMfaRequestMutation>;
export type VerifyMfaRequestMutationResult = Apollo.MutationResult<VerifyMfaRequestMutation>;
export type VerifyMfaRequestMutationOptions = Apollo.BaseMutationOptions<VerifyMfaRequestMutation, VerifyMfaRequestMutationVariables>;
export const GenerateMfaSecretDocument = gql`
    mutation generateMFASecret {
  generateMFASecret {
    secret
    uri
    qr
  }
}
    `;
export type GenerateMfaSecretMutationFn = Apollo.MutationFunction<GenerateMfaSecretMutation, GenerateMfaSecretMutationVariables>;

/**
 * __useGenerateMfaSecretMutation__
 *
 * To run a mutation, you first call `useGenerateMfaSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMfaSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMfaSecretMutation, { data, loading, error }] = useGenerateMfaSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateMfaSecretMutation(baseOptions?: Apollo.MutationHookOptions<GenerateMfaSecretMutation, GenerateMfaSecretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateMfaSecretMutation, GenerateMfaSecretMutationVariables>(GenerateMfaSecretDocument, options);
      }
export type GenerateMfaSecretMutationHookResult = ReturnType<typeof useGenerateMfaSecretMutation>;
export type GenerateMfaSecretMutationResult = Apollo.MutationResult<GenerateMfaSecretMutation>;
export type GenerateMfaSecretMutationOptions = Apollo.BaseMutationOptions<GenerateMfaSecretMutation, GenerateMfaSecretMutationVariables>;
export const DownloadCsvDocument = gql`
    query downloadCSV($id: String) {
  storeFinderCSVData(storeFinderSearchId: $id)
}
    `;

/**
 * __useDownloadCsvQuery__
 *
 * To run a query within a React component, call `useDownloadCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCsvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadCsvQuery(baseOptions?: Apollo.QueryHookOptions<DownloadCsvQuery, DownloadCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadCsvQuery, DownloadCsvQueryVariables>(DownloadCsvDocument, options);
      }
export function useDownloadCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadCsvQuery, DownloadCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadCsvQuery, DownloadCsvQueryVariables>(DownloadCsvDocument, options);
        }
export type DownloadCsvQueryHookResult = ReturnType<typeof useDownloadCsvQuery>;
export type DownloadCsvLazyQueryHookResult = ReturnType<typeof useDownloadCsvLazyQuery>;
export type DownloadCsvQueryResult = Apollo.QueryResult<DownloadCsvQuery, DownloadCsvQueryVariables>;
export const StoreFinderSearchCombinedResultsDocument = gql`
    query storeFinderSearchCombinedResults($amountToSkip: Float!, $searchTerm: String!) {
  storeFinderSearchCombinedResults(
    amountToSkip: $amountToSkip
    searchTerm: $searchTerm
  ) {
    results {
      locationName
      queryDate
      numberOfSearchResults
      storeFinderSearchId
      storeTypes
      maxResultTypes
      searchRadius
      numberOfMatchingProvidersFound
      numberOfEcommerceResults
      csvData
      email
    }
    totalCount
  }
}
    `;

/**
 * __useStoreFinderSearchCombinedResultsQuery__
 *
 * To run a query within a React component, call `useStoreFinderSearchCombinedResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreFinderSearchCombinedResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreFinderSearchCombinedResultsQuery({
 *   variables: {
 *      amountToSkip: // value for 'amountToSkip'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useStoreFinderSearchCombinedResultsQuery(baseOptions: Apollo.QueryHookOptions<StoreFinderSearchCombinedResultsQuery, StoreFinderSearchCombinedResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreFinderSearchCombinedResultsQuery, StoreFinderSearchCombinedResultsQueryVariables>(StoreFinderSearchCombinedResultsDocument, options);
      }
export function useStoreFinderSearchCombinedResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreFinderSearchCombinedResultsQuery, StoreFinderSearchCombinedResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreFinderSearchCombinedResultsQuery, StoreFinderSearchCombinedResultsQueryVariables>(StoreFinderSearchCombinedResultsDocument, options);
        }
export type StoreFinderSearchCombinedResultsQueryHookResult = ReturnType<typeof useStoreFinderSearchCombinedResultsQuery>;
export type StoreFinderSearchCombinedResultsLazyQueryHookResult = ReturnType<typeof useStoreFinderSearchCombinedResultsLazyQuery>;
export type StoreFinderSearchCombinedResultsQueryResult = Apollo.QueryResult<StoreFinderSearchCombinedResultsQuery, StoreFinderSearchCombinedResultsQueryVariables>;
export const StoreFinderSearchProgressDocument = gql`
    query storeFinderSearchProgress($searchId: String!) {
  storeFinderSearchProgress(storeFinderSearchId: $searchId)
}
    `;

/**
 * __useStoreFinderSearchProgressQuery__
 *
 * To run a query within a React component, call `useStoreFinderSearchProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreFinderSearchProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreFinderSearchProgressQuery({
 *   variables: {
 *      searchId: // value for 'searchId'
 *   },
 * });
 */
export function useStoreFinderSearchProgressQuery(baseOptions: Apollo.QueryHookOptions<StoreFinderSearchProgressQuery, StoreFinderSearchProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreFinderSearchProgressQuery, StoreFinderSearchProgressQueryVariables>(StoreFinderSearchProgressDocument, options);
      }
export function useStoreFinderSearchProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreFinderSearchProgressQuery, StoreFinderSearchProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreFinderSearchProgressQuery, StoreFinderSearchProgressQueryVariables>(StoreFinderSearchProgressDocument, options);
        }
export type StoreFinderSearchProgressQueryHookResult = ReturnType<typeof useStoreFinderSearchProgressQuery>;
export type StoreFinderSearchProgressLazyQueryHookResult = ReturnType<typeof useStoreFinderSearchProgressLazyQuery>;
export type StoreFinderSearchProgressQueryResult = Apollo.QueryResult<StoreFinderSearchProgressQuery, StoreFinderSearchProgressQueryVariables>;