import { AuthenticationState } from './AuthenticationState';
import { UserRole } from './UserRole';
export interface AuthenticationResult {
    state: AuthenticationState;
    userInfo?: AuthenticatedUserInfo;
    setAuthenticationResult?: (
        state: AuthenticationState,
        userInfo: AuthenticatedUserInfo | undefined,
    ) => void;
}

export interface AuthenticatedUserInfo {
    name: string;
    role: UserRole;
    email: string;
    hasMFA: boolean;
    mfaID?: string;
    hasStoreFinderAccess?: boolean;
}

export const defaultAuthenticationResult: AuthenticationResult = {
    state: AuthenticationState.AwaitingAuthentication,
};
