import _ from 'lodash';

export enum StoreCategory {
    comparison = 'COMPARISON',
    convenience = 'CONVENIENCE',
    leisure = 'LEISURE',
    service = 'SERVICE',
    houses_retailers = 'HOUSES RETAILERS',
    other = 'OTHER/NON-RETAIL',
}

export interface Store {
    name: string;
    category: StoreCategory;
}

export const allStores: Store[] = [
    {
        name: 'bicycle_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'book_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'clothing_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'drugstore',
        category: StoreCategory.comparison,
    },
    {
        name: 'electronics_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'florist',
        category: StoreCategory.comparison,
    },
    {
        name: 'furniture_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'hardware_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'home_goods_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'jewelry_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'liquor_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'pet_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'pharmacy',
        category: StoreCategory.comparison,
    },
    {
        name: 'shoe_store',
        category: StoreCategory.comparison,
    },
    {
        name: 'store',
        category: StoreCategory.comparison,
    },
    {
        name: 'bakery',
        category: StoreCategory.convenience,
    },
    {
        name: 'convenience_store',
        category: StoreCategory.convenience,
    },
    {
        name: 'gas_station',
        category: StoreCategory.convenience,
    },
    {
        name: 'supermarket',
        category: StoreCategory.convenience,
    },
    {
        name: 'bar',
        category: StoreCategory.leisure,
    },
    {
        name: 'casino',
        category: StoreCategory.leisure,
    },
    {
        name: 'cafe',
        category: StoreCategory.leisure,
    },
    {
        name: 'meal_delivery',
        category: StoreCategory.leisure,
    },
    {
        name: 'movie_rental',
        category: StoreCategory.leisure,
    },
    {
        name: 'movie_theater',
        category: StoreCategory.leisure,
    },
    {
        name: 'night_club',
        category: StoreCategory.leisure,
    },
    {
        name: 'bank',
        category: StoreCategory.service,
    },
    {
        name: 'beauty_salon',
        category: StoreCategory.service,
    },
    {
        name: 'car_dealer',
        category: StoreCategory.service,
    },
    {
        name: 'car_rental',
        category: StoreCategory.service,
    },
    {
        name: 'car_repair',
        category: StoreCategory.service,
    },
    {
        name: 'car_wash',
        category: StoreCategory.service,
    },
    {
        name: 'dentist',
        category: StoreCategory.service,
    },
    {
        name: 'doctor',
        category: StoreCategory.service,
    },
    {
        name: 'electrician',
        category: StoreCategory.service,
    },
    {
        name: ' hair_care',
        category: StoreCategory.service,
    },
    {
        name: ' laundry',
        category: StoreCategory.service,
    },
    {
        name: 'locksmith',
        category: StoreCategory.service,
    },
    {
        name: 'lodging',
        category: StoreCategory.service,
    },
    {
        name: 'moving_company',
        category: StoreCategory.service,
    },
    {
        name: 'physiotherapist',
        category: StoreCategory.service,
    },
    {
        name: 'plumber',
        category: StoreCategory.service,
    },
    {
        name: 'post_office',
        category: StoreCategory.service,
    },
    {
        name: 'real_estate_agency',
        category: StoreCategory.service,
    },
    {
        name: 'restaurant',
        category: StoreCategory.service,
    },
    {
        name: 'roofing_contractor',
        category: StoreCategory.service,
    },
    {
        name: 'spa',
        category: StoreCategory.service,
    },
    {
        name: 'storage',
        category: StoreCategory.service,
    },
    {
        name: 'travel_agency',
        category: StoreCategory.service,
    },
    {
        name: 'veterinary_care',
        category: StoreCategory.service,
    },
    {
        name: 'airport',
        category: StoreCategory.houses_retailers,
    },
    {
        name: ' art_gallery',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'aquarium',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'department_store',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'shopping_mall',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'subway_station',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'train_station',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'zoo',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'university',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'amusement_park',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'museum (gift shops)',
        category: StoreCategory.houses_retailers,
    },
    {
        name: 'accounting',
        category: StoreCategory.other,
    },
    {
        name: 'atm',
        category: StoreCategory.other,
    },
    {
        name: 'bowling_alley',
        category: StoreCategory.other,
    },
    {
        name: 'bus_station',
        category: StoreCategory.other,
    },
    {
        name: 'cemetery',
        category: StoreCategory.other,
    },
    {
        name: 'church',
        category: StoreCategory.other,
    },
    {
        name: 'city_hall',
        category: StoreCategory.other,
    },
    {
        name: 'campground',
        category: StoreCategory.other,
    },
    {
        name: 'courthouse',
        category: StoreCategory.other,
    },
    {
        name: 'embassy',
        category: StoreCategory.other,
    },
    {
        name: 'fire_station',
        category: StoreCategory.other,
    },
    {
        name: 'funeral_home',
        category: StoreCategory.other,
    },
    {
        name: 'gym',
        category: StoreCategory.other,
    },
    {
        name: 'hindu_temple',
        category: StoreCategory.other,
    },
    {
        name: 'hospital',
        category: StoreCategory.other,
    },
    {
        name: 'insurance agency',
        category: StoreCategory.other,
    },
    {
        name: 'lawyer',
        category: StoreCategory.other,
    },
    {
        name: 'library',
        category: StoreCategory.other,
    },
    {
        name: 'light_rail_station',
        category: StoreCategory.other,
    },
    {
        name: 'local_government_office',
        category: StoreCategory.other,
    },
    {
        name: 'mosque',
        category: StoreCategory.other,
    },
    {
        name: 'painter',
        category: StoreCategory.other,
    },
    {
        name: 'park',
        category: StoreCategory.other,
    },
    {
        name: 'parking',
        category: StoreCategory.other,
    },
    {
        name: 'police',
        category: StoreCategory.other,
    },
    {
        name: 'primary_school',
        category: StoreCategory.other,
    },
    {
        name: 'rv_park',
        category: StoreCategory.other,
    },
    {
        name: 'school',
        category: StoreCategory.other,
    },
    {
        name: 'secondary_school',
        category: StoreCategory.other,
    },
    {
        name: 'stadium',
        category: StoreCategory.other,
    },
    {
        name: 'synagogue',
        category: StoreCategory.other,
    },
    {
        name: 'taxi_stand',
        category: StoreCategory.other,
    },
    {
        name: 'tourist_attraction',
        category: StoreCategory.other,
    },
    {
        name: 'transit_station',
        category: StoreCategory.other,
    },
];

export const getAllStoresOfType = (category: StoreCategory) => {
    return _.filter(allStores, (store: Store) => store.category === category);
};
