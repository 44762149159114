import React, { useEffect } from 'react';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import styles from './authpages.module.css';
import { useAuthenticatedSession } from '../../hooks/useAuthenticatedSession';
import { AuthenticationState } from '../../utils/authentication/AuthenticationState';

const LoginPage: React.FC = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [didError, setDidError] = React.useState(false);
    const authenticationResult = useAuthenticatedSession();

    useEffect(() => {
        if (authenticationResult.state === AuthenticationState.Authenticated) {
            window.location.replace('/')
        }
    }, [authenticationResult]);

    const handleLogin = async () => {
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch {
            setDidError(true);
        }
    };

    return (
        <div className={styles.container}>
            <img src="/img/cloudshelf_long_logo.png" alt="Cloudshelf" style={{ width: 243 }} />
            <form
                onSubmit={e => {
                    void handleLogin();
                    e.preventDefault();
                }}
                className={styles.formContainer}
            >
                <h1 className={styles.h1}>Sign in to your account</h1>

                <label htmlFor="loginBox" className={styles.label}>
                    Email address
                </label>
                <input
                    autoComplete="username"
                    type="text"
                    id="loginBox"
                    value={email}
                    className={`${styles.textInput} ${didError ? styles.errorInput : ''}`}
                    onChange={e => setEmail(e.target.value)}
                />

                <label htmlFor="passwordBox" className={styles.label}>
                    Password
                </label>
                <input
                    autoComplete="current-password"
                    type="password"
                    id="passwordBox"
                    value={password}
                    className={`${styles.textInput} ${didError ? styles.errorInput : ''}`}
                    onChange={e => setPassword(e.target.value)}
                />

                {didError && (
                    <div className={styles.errorContainer}>
                        <div>
                            <p className={styles.errorText}>Invalid email or password</p>
                        </div>
                    </div>
                )}

                <button className={styles.button} onClick={handleLogin}>
                    Sign in
                </button>
            </form>
        </div>
    );
};

export default LoginPage;
